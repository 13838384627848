<template>
<div class="search-box-ex-annex">
  <div
      class="outer d-flex flex-grow-1 h-100"
      :class="isFocused ? 'outer-focused' : 'outer-no-focus'"
    >
    <input
      class="search-box d-flex flex-grow-1 my-auto"
      type="text"
      :placeholder="placeholder"
      :name="name"
      v-model="currentTextValue"
    >
    <span
      class="search-box button-group d-flex"
      :class="isFocused ? 'text-focused' : ''"
    >
      <slot name="aux" v-bind:query="currentTextValue"></slot>
      <button
        class="search-button"
        type="submit"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
        </svg>
      </button>
    </span>
  </div>
</div>
</template>

<script>
// We'll use the annex to attach the drop-down menu and other goodies.

// TODO: Implement options button ... to search in new tab.

export default {
  props:
  {
    placeholder: null,
    name: null,
    value: null
  },
  data()
  {
    return {
      textElement: null,
      isFocused: false,
      currentTextValue: this.value
    }
  },
  methods:
  {
    submit()
    {
      // Handle CTRL+ENTER to open a new tab.

    },
    focusSearchEventHandler(e)
    {
      if (e.key == '/')
      {
        if (this.focusSearch() === true)
        {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    },
    focusSearch()
    {
      let searchBox = this.textElement;

      // If the search box is already active, we'll ignore the command
      // so that the key press can be handled by the browser and show in
      // the input.
      if (searchBox != null && document.activeElement != searchBox)
      {
        searchBox.focus();
        searchBox.select();

        return true;
      }

      return false;
    }
  },
  computed()
  {
  },
  mounted()
  {
    this.textElement = this.$el.querySelector('input.search-box');

    // When the user presses the backslash key, the search box will become
    // active and its text will be selected.
    // On the text selection, the user should be able to specify whether
    // it is enabled or not.
    //
    // We'll add the event listener to the window object for a global
    // shortcut.
    window.addEventListener('keydown', this.focusSearchEventHandler);

    this.textElement.addEventListener('focus', () => this.isFocused = true);
    this.textElement.addEventListener('blur', () => this.isFocused = false);
  },
  unmounted()
  {
    window.removeEventListener('keydown', this.focusSearchEventHandler);
  }
};
</script>

<style lang="sass">

@use '/src/styles/variables/box_shadow'

$text_area_normal_background_color: #fff9
$text_area_focused_background_color: #fff

$transition_timings: all ease 0.20s 0.05s

.outer
  cursor: text

  border: solid 1px #c9bfc8
  border-radius: 5px
  transition: $transition_timings

  background: $text_area_normal_background_color

.outer-focused
  cursor: inherit

  // box-shadow: 0px 0px 2px #f33bb0
  box-shadow: box_shadow.$input_box_shadow
  background: $text_area_focused_background_color

@keyframes fade-in
  to
    cursor: inherit
    pointer-events: all
    opacity: 1.0
    display: inherit

@keyframes fade-out
  to
    pointer-events: none
    opacity: 0
    display: none !important

.button-group
  animation: fade-out ease 0.25s 0.1s forwards

.button-group.text-focused
  animation: fade-in ease 0.25s 0.1s forwards

.button-group > button
  width: 50px
  height: 100%
  color: #898989
  background: transparent
  border: none
  transition: $transition_timings

.button-group > button:last-child
  border-radius: 0 5px 5px 0
  padding-right: 1rem

button.search-button
  // Excluding to take attention away from the search button.
  // border-left: 1px #dbdbdb solid


.button-group.text-focused > button:hover
  color: #484242

input[type="text"].search-box
  font-size: 1.3rem
  height: 3.2rem
  color: #4d4d4d
  width: 100%
  border: none
  padding-left: 0.75rem
  border-radius: 5px 0 0 5px

  background: transparent

  // border: solid 2px #c9bfc8
  // border-color: rgb(161, 155, 134);

  &::-webkit-input-placeholder
    color: rgb(61, 61, 61)
    opacity: 0.65

  &::-moz-placeholder
    color: rgb(61, 61, 61)
    opacity: 0.65

  // Remove the focus outline in Firefox and Waterfox.
  &:focus
    outline: none

</style>
