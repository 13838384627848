<template>
  <!-- Pass props -->
  <component :is="searchResultTemplate" v-bind="$attrs" />
</template>

<script type="text/javascript">
  import { defineAsyncComponent, markRaw } from 'vue';


  // import SearchResultVisual from './search-result-visual.vue';


  export default
  {
    components:
    {
      SearchResultMinimal: defineAsyncComponent(
        () => import('./search-result-minimal.vue')
      ),
      SearchResultCard: defineAsyncComponent(
        () => import('./search-result-card.vue')
      )
    },
    data()
    {
      let resultLayout = 'SearchResultCard';

      if (window.localStorage)
      {

        switch(window.localStorage.getItem('result_layout'))
        {
          case 'detailed':
          {
            resultLayout = 'SearchResultMinimal';
            break;
          }
          default:
            resultLayout = 'SearchResultCard';
        }
      }

      return {
        searchResultTemplate: resultLayout
      }
    }
  }

</script>

