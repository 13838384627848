import api from "!../../../.yarn/__virtual__/style-loader-virtual-0e083c4e9f/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-21425246a5.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../.yarn/__virtual__/css-loader-virtual-9392e7c121/0/cache/css-loader-npm-5.2.0-c409834e49-1d3a8b6362.zip/node_modules/css-loader/dist/cjs.js!../../../.yarn/__virtual__/vue-loader-virtual-0c21917e0a/0/cache/vue-loader-npm-17.0.1-f552b82805-42cc8c8ff0.zip/node_modules/vue-loader/dist/stylePostLoader.js!../../../.yarn/__virtual__/vue-loader-virtual-0c21917e0a/0/cache/vue-loader-npm-17.0.1-f552b82805-42cc8c8ff0.zip/node_modules/vue-loader/dist/index.js??ruleSet[0].use[0]!./search-results.vue?vue&type=style&index=0&id=1c110dae&lang=css";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};