"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class SearchResults {
    constructor() {
        this._results = new Array();
    }
    get results() {
        return this._results;
    }
    containsResult(result) {
        return this.results.some((placedResult) => placedResult.productUrl.toString() === result.productUrl.toString());
    }
    addResults(newResults, allowDuplicates = false) {
        newResults.forEach((result) => {
            if (allowDuplicates || (!allowDuplicates && !this.containsResult(result))) {
                this.results.push(result);
            }
        });
    }
}
exports.default = SearchResults;
