<template>
  <div class="row results mx-0" :search-key="searchKey">
    <template v-for="(result, index) in results.results" v-bind:key="index">
      <search-result-adaptive v-bind="result" />
    </template>
  </div>
</template>

<script>
  import SearchResultAdaptive from './results/search-result-adaptive.vue';
  import SearchResults from '../lib/SearchResults';
  import { delay } from 'lodash';

  // data-masonry='{"percentPosition": true }'

  // let searchResults = new SearchResults();

  export default {
    components: {
      SearchResultAdaptive
    },
    emits: [
      'resultsConcluded'
    ],
    inject: [
      'isIntersecting'
    ],
    watch:
    {
      isIntersecting:
      {
        handler: function (isIntersecting, prev)
        {
          if (isIntersecting.value)
          {
            this.loadResults();
          }
        },
        deep: true
      }
    },
    mounted()
    {
      // this.initObservation();


      // this.addResults(JSON.parse(this.initialResults));
      this.results.addResults(JSON.parse(this.initialResults));

      // Set is loading results only after the results were added for
      // the first time.
      delay(
        () => {
          this.isLoadingResults = false;
        },
        5000
      );
    },
    unmounted()
    {
      // Remove observation.
    },
    props: {
      searchKey: {
        type: String,
        required: true
      },
      initialResults: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isLoadingResults: true,
        endOfResults: false,
        results: new SearchResults()
      }
    },
    methods:
    {
      ingestJSON(value)
      {
        try
        {
          return JSON.parse(
            (new DOMParser())
              .parseFromString(value, 'text/html')
          );
        }
        catch (e) { }
      },
      loadResults()
      {
        this.$emit('resultsConcluded', this.endOfResults);

        if (this.isLoadingResults || this.endOfResults)
        {
          if (this.endOfResults)
          {
            this.isLoadingResults = false;
          }

          return;
        }
        else
        {
          this.isLoadingResults = true;

          let nextPageUri =
            `${process.env.DOMAIN}/search/next/${this.searchKey}`;

          this
          .$http
          .post(nextPageUri)
          .then((response) => {
            let newResults = response.data.data.results;

            if (newResults.length > 0)
            {
              this.results.addResults(newResults);
            }
            else
            {
              this.endOfResults = true;
            }

          })
          .finally((data) => {
            this.isLoadingResults = false;
            this.$emit('resultsConcluded', this.endOfResults);
          });
        }
      }
    }
  }

</script>

<style>
#search-results-end
{
  height: 3rem;
  background: transparent;
}
</style>
