import ApplicationView from './application-view.js';

export default class Application
{
  constructor()
  {
    this.app = this.view();
  }

  view()
  {
    return ApplicationView;
  }

  start(mountElement = '#container')
  {
    this.app.mount(mountElement);

    return true;
  }
};
