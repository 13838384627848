<template>
  <button @click="openInNewTab" :query="query" type="button">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-front" viewBox="0 0 16 16">
      <path d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm5 10v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2v5a2 2 0 0 1-2 2H5z"/>
    </svg>
  </button>
</template>

<script type="text/javascript">
export default {
  props:
  {
    query: null
  },
  data()
  {
    return {
    };
  },
  mounted()
  {
  },
  methods:
  {
    openInNewTab(e)
    {
      e.preventDefault();
      e.stopPropagation();

      let formattedQuery = encodeURI(this.query);
      let queryUrl = `${process.env.DOMAIN}/search?query=${formattedQuery}`;

      window.open(queryUrl);

      e.target.blur();
    }
  }
}
</script>
