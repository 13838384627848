<template>
  <infinite-scroll-container>
    <search-results
      v-bind:search-key="searchKey"
      v-bind:initial-results="initialResults"
    />
  </infinite-scroll-container>
</template>

<script>
  import InfiniteScrollContainer from './infinite-scroll-container.vue';
  import SearchResults from './search-results.vue';
  import SearchResultAdaptive from './results/search-result-adaptive.vue';
  import { DebugHelper } from '../lib/helpers.js';

  export default {
    components: {
      InfiniteScrollContainer,
      SearchResults,
      SearchResultAdaptive
    },
    props: {
      searchKey: {
        type: String,
        required: true
      },
      initialResults: {
        type: String,
        required: true
      },
      query: {
        type: String,
        required: true
      }
    },
    data()
    {
      return {}
    },
    mounted()
    {
      if (DebugHelper.inDebugMode())
      {
        console.log(`Query: ${this.query}`);
        console.log(`Search Key: ${this.searchKey}`);
        console.log(`Initial search results: ${this.initialResults}`);
      }
    }
  }

</script>
