import { createApp, defineAsyncComponent} from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';
// import PhotoSwipe from 'photoswipe';

import SearchBoxEx from '../components/search-box-ex.vue';
import InfiniteSearchResults from '../components/infinite-search-results.vue';
import SearchResultAdaptive from '../components/results/search-result-adaptive.vue';
import SearchNewTabButton from '../components/search-new-tab-button.vue';

// Not immediately necessary. Can be loaded-loaded.
// import TippedUserSettings from '../components/tipped-user-settings.vue';
// import UserAccountPage from '../components/pages/user-account-page.vue';
// import SearchUtilities from '../components/search-utilities.vue';
// import AdvancedSearch from '../components/advanced-search.vue';

// import Headroom from 'headroom.js';

const app = createApp({
  components: {
    SearchBoxEx,
    InfiniteSearchResults,
    SearchResultAdaptive,
    SearchNewTabButton,

    UserAccountPage: defineAsyncComponent(
      () => import('../components/pages/user-account-page.vue')
    ),
    UserRegistrationPage: defineAsyncComponent(
      () => import('../components/pages/user-registration-page.vue')
    ),
    TippedUserSettings: defineAsyncComponent(
      () => import('../components/tipped-user-settings.vue')
    ),
    SearchUtilities: defineAsyncComponent(
      () => import('../components/search-utilities.vue')
    ),
    AdvancedSearch: defineAsyncComponent(
      () => import('../components/advanced-search.vue')
    )
  },
  data: function()
  {
    return {
      enableTracking: true,
      // States whether general enhancement is enabled.
      // For example, the preview pane is enabled by default.
      enhanced: false,
      // TODO: Implement
      // Sets the active state of the preview pane, if the 'enhanced' variable
      // is set and this is false, the pane will not be shown.
      enabledPreviewPane: true,
      showPreview: false,
      loadingPreview: false,
      productGallery: null,
      showSidebar: false,
      item:
      {
        name: 'Placeholder',
        price: '',
        url: '',
        image: '',
        gallery: [],
        hasGallery: false
      }
    };
  },
  created: function()
  {
  },
  mounted: function()
  {
    this.enhanced = true;

    // TODO: Implment headroom
    // let header = document.querySelector('.sticky-search-form');
    // if (header)
    // {
    //   let headroom = new Headroom(header);
    //   headroom.init();
    //
    //   TODO: Check if we're cruising. If so, hide the navbar, but enable it
    //   when we're done.
    // }

    // TODO: If the user selects something, let's allow them to create
    // a search for it.
    // document.addEventListener('selectionchange', (e) => {
    //   console.log(document.getSelection());
    // });
  },
  methods:
  {
    toggleUserMenu(e)
    {
      // We're opening the sidebar now, so we'll need to first close the
      // preview, if it's open.
      if (!this.showSidebar)
      {
        closePreview();
      }

      this.showSidebar = !this.showSidebar;
    },
    openGallery(e)
    {
      if (this.productGallery != undefined)
      {
        this.productGallery.init();
      }
    },
    showApplicationMenu(e)
    {
      // TODO: Implement application menu or sidebar for mobile users or those
      // with smaller screens.
    },
    closePreview()
    {
      this.showPreview = false;
    },
    rowInteraction(e)
    {
      /*if (e.target && !e.target.classList.contains('product-link'))
      {
        e.preventDefault();
        e.stopPropagation();

        let productURL = e.currentTarget.getAttribute('data-product-url');

        this.item.image = '/assets/loading_12.gif';
        this.loadingPreview = true;

        this.$http.get('/product?query=' + productURL)
          .then((response) => {
            try
            {
              let itemGallery = response.data.gallery;
              this.item = response.data;
              this.item.url = productURL;

              if (response.data.gallery != null)
              {
                this.item.gallery = [];

                for (let i = 0; i < itemGallery.length; i++)
                {
                  let galleryImage = {
                    src: itemGallery[i],
                    thumbnail: itemGallery[i],
                    w: 800,
                    h: 800,
                    alt: 'image'
                  };

                  this.item.gallery.push(galleryImage);
                }

                this.item.hasGallery = this.item.gallery != null
                  && this.item.gallery.length > 0;

                let galleryRoot = document.querySelector('.pswp');
                if (galleryRoot != null)
                {
                  this.productGallery = new PhotoSwipe(
                    galleryRoot,
                    PhotoSwipeUI_Default,
                    this.item.gallery
                  );
                }
              }

              // let itemImage = e.currentTarget.getAttribute('data-hobby-item');
              // this.item.image = itemImage;
              // console.log(this.item);

              this.loadingPreview = false;
            }
            catch (e)
            {
              this.item = {};
              console.error(e);
            }
          });

          // We need to close the sidebar before showing the preview.
          if (this.showSidebar)
          {
            toggleUserMenu();
          }

          this.showPreview = true;
      } */
    },
    addShopTracking(url)
    {
      if (url && url.match(/\/redir\/?\?url/i) == null)
      {
        let shopUrl = null;
        shopUrl = escape(url);
        shopUrl = `/redir?url=${shopUrl}`;

        return shopUrl;
      }
      else
      {
        return url;
      }
    },
    trackShop(e)
    {
      if (this.enableTracking)
      {
        let shopUrl = null;

        try
        {
          if (e.currentTarget)
          {
            shopUrl = this.addShopTracking(e.currentTarget.href);

            e.currentTarget.href = shopUrl;
            e.preventDefault();
            e.stopPropagation();
          }
        }
        catch (e)
        {
        }
      }

    },
    visitItemPage(e)
    {
      e.preventDefault();
      e.stopPropagation();

      let win = window.open(e.currentTarget.href, '_blank');
      win.location();

    }
  }
});

app.use(VueAxios, axios);

export default app;
