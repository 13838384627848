<template>
  <div
    id="infinite-scroll-container"
    class="min-vh-100"
    @results-concluded="resultsConcludedHandler"
  >
    <div>
      <slot />
    </div>

    <div id="loader" class="row p-0 w-100 m-auto">
      <div
        v-show="showLoader"
        class="col text-center py-4 my-auto fs-bolder"
      >
        <ellipsis-loader />
      </div>
    </div>
  </div>
</template>

<script>
  import EllipsisLoader from './loaders/ellipsis-loader.vue';
  import { computed } from 'vue';
  import { DebugHelper } from '../lib/helpers.js';

  export default {
    components: {
      EllipsisLoader
    },
    mounted()
    {
      this.$nextTick(function() {
        this.startObserving();
      });
    },
    unmounted()
    {
      this.stopObserving();
    },
    provide()
    {
      return {
        isIntersecting: computed(() => this.isIntersecting)
      }
    },
    data()
    {
      return {
        // isLoadingResults: false,
        // endOfResults: false,
        // results: [],
        observer: null,
        isIntersecting: false,
        atResultEnd: false,
      }
    },
    methods:
    {
      showLoader()
      {
        return !this.atResultEnd && this.isIntersecting;
      },
      resultsConcludedHandler(e)
      {
        this.atResultEnd = e;
      },
      startObserving()
      {
        if (DebugHelper.inDebugMode())
        {
          console.log('Starting observation...');
        }

        let watch = (entries, observer) => {
          entries.forEach(
            entry => {
              this.isIntersecting = entry.isIntersecting;
            }
          );
        };

        let rootNode = document.querySelector('#infinite-scroll-container');

        if (rootNode)
        {
          let options = {
            root: null,
            threshold: 0,
            rootMargin: '-300px 0px 0px 0px'
          };

          this.observer = new IntersectionObserver(watch, options);

          if (DebugHelper.inDebugMode())
          {
            console.log(this.observer);
          }

          this.observer.observe(
            document.querySelector(
              '#loader'
            )
          );

          if (DebugHelper.inDebugMode())
          {
            console.log('Observing.');
          }
        }
      },
      stopObserving()
      {
        if (this.observer)
        {
          this.observer.disconnect();
        }
      }
    }
  }
</script>

<!-- Cute: https://order.mandarake.co.jp/order/detailPage/item?itemCode=1202420855&ref=list&goodsSellType=1&categoryCode=02&keyword=bunny&lang=en -->
<!-- Cute: https://order.mandarake.co.jp/order/detailPage/item?itemCode=1202697560&ref=list&goodsSellType=1&categoryCode=02&keyword=%E6%99%82%E5%B4%8E%E7%8B%82%E4%B8%89 -->
<!-- Cute: https://order.mandarake.co.jp/order/detailPage/item?itemCode=1197928718&ref=list&goodsSellType=1&categoryCode=02&keyword=%E6%99%82%E5%B4%8E%E7%8B%82%E4%B8%89 -->
