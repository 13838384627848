// import '../styles/style.scss';
import Application from './lib/application.js';

const loadSentry = async () => {
  if (process.env.SENTRY_DSN && process.env.SENTRY_DSN !== '')
  {
    const Sentry = await import('@sentry/browser');
    // import * as Sentry from '@sentry/browser';
    
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      tracesSampleRate: 0.3,
      sendDefaultPii: false
    });
  }
}

const deactivateNjsOverlay = async () => {
  try
  {
    const inactiveAnimationClass = 'njs-overlay-inactive-animation';
    let njsOverlay = document.querySelector('div.njs-overlay');

    if (njsOverlay)
    {
      njsOverlay.classList.add(inactiveAnimationClass);

      njsOverlay.addEventListener(
        'animationend',
        (e) => {
          e.target.classList.remove(inactiveAnimationClass);
          e.target.classList.add('njs-overlay-inactive');
        }
      );
    }
  }
  catch (e)
  {
    // console.error(e);
  }
}

const applicationStartUp = async () => {
  await loadSentry();

  let applicationMain = new Application();

  if (applicationMain.start('#main'))
  {
    // Don't bother waiting.
    deactivateNjsOverlay();
  }

  // console.log('Application mounted');
}

window.addEventListener('load', applicationStartUp);
